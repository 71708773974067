import React from 'react'

import { Global, css } from '@emotion/react'
import emotionNormalize from 'emotion-normalize'

const GlobalCss = ({
  theme,
}: {
  theme: {
    main: string
    border: string
    textColor: string
    textSelectionColor: string
  }
}) => (
  <Global
    styles={css`
      ${emotionNormalize}
      :root {
        --main-bg-color: ${theme.main};
        --border-bg-color: ${theme.border};
        --text-color: ${theme.textColor};
        --text-selection-color: ${theme.textSelectionColor};
      }
      ::selection {
        background: rgb(var(--text-selection-color));
      }
      html {
        background-color: rgb(var(--border-bg-color));
      }
      html,
      body {
        display: flex;
        padding: 0;
        margin: 0;
        min-height: 100%;
        min-width: 100%;
        font-family: Alegreya;
        color: rgb(var(---text-color));
        font-size: 1vw;
      }
      * {
        box-sizing: border-box;
      }
      @media (max-aspect-ratio: 1/1) {
        body {
          font-size: 2vw;
        }
      }
    `}
  />
)

export default GlobalCss
