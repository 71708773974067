/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useCallback, useState } from 'react'
import GlobalCss from './globalCss'
import { css } from '@emotion/react'
import ThemeSwitcher from './themeSwitcher'

const themes = [
  {
    main: '253, 226, 226',
    border: '170, 207, 207',
    textColor: '0, 0, 0',
    textSelectionColor: '103, 155, 155',
  },
  {
    main: '235, 236, 241',
    border: '32, 106, 93',
    textColor: '0, 0, 0',
    textSelectionColor: '31, 64, 104',
  },
  {
    main: '246, 158, 123',
    border: '56, 62, 86',
    textColor: '0, 0, 0',
    textSelectionColor: '212, 181, 176',
  },
  {
    main: '244, 246, 255',
    border: '251, 212, 109',
    textColor: '0, 0, 0',
    textSelectionColor: '79, 138, 139',
  },
  {
    main: '255, 224, 172',
    border: '104, 134, 197',
    textColor: '0, 0, 0',
    textSelectionColor: '255, 172, 183',
  },
  {
    main: '255, 255, 255',
    border: '255, 232, 223',
    textColor: '0, 0, 0',
    textSelectionColor: '136, 136, 136',
  },
  {
    main: '255, 211, 182',
    border: '105, 132, 116',
    textColor: '0, 0, 0',
    textSelectionColor: '255, 170, 165',
  },
]

const Layout = ({ children }: { children: React.ReactNode }) => {
  const [themeIndex, setThemeIndex] = useState<number>(1)

  const moveToNextTheme = useCallback(() => {
    setThemeIndex((themeIndex + 1) % themes.length)
  }, [themeIndex, setThemeIndex])

  return (
    <>
      <GlobalCss theme={themes[themeIndex]} />
      <div
        css={css`
          background-color: rgb(var(--border-bg-color));
          height: 100%;
        `}
      >
        <main
          css={css`
            border: 1em solid rgb(var(--border-bg-color));
            height: 100%;
            /* height: calc(100% - 2em);
            width: calc(100% - 2em); */
            border-bottom: none;
            position: relative;
            background-color: rgb(var(--main-bg-color));
            overflow: auto;
            display: flex;
            align-items: center;
            @media (max-aspect-ratio: 1/1) {
              align-items: baseline;
            }
            &:after {
              position: fixed;
              z-index: 1200;
              content: ' ';
              display: block;
              top: auto;
              bottom: 0;
              left: 0;
              right: 0;
              height: 1em;
              width: 100%;
              background: rgb(var(--border-bg-color));
            }
            &:before {
              position: fixed;
              z-index: 1200;
              content: ' ';
              display: block;
              top: 0;
              left: 0;
              right: 0;
              height: 1em;
              width: 100%;
              background: rgb(var(--border-bg-color));
            }
          `}
        >
          <ThemeSwitcher
            nextTheme={themes[(themeIndex + 1) % themes.length]}
            onChangeThemeCallback={moveToNextTheme}
          />
          {children}
        </main>
      </div>
    </>
  )
}

export default ({ element }: { element: any }) => <Layout>{element}</Layout>
