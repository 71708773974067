import React from 'react'
import { motion } from 'framer-motion'
import { css } from '@emotion/react'

interface Props {
  nextTheme: {
    border: string
    main: string
    textColor: string
    textSelectionColor: string
  }
  onChangeThemeCallback: (event: any) => void
}

const containerVariants = {
  unhovered: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  hover: {},
}

const colorVariants = (delay: number) => ({
  unhovered: {
    scale: [1, 1.2, 1],
    transition: {
      yoyo: Infinity,
      duration: 0.1,
      delay,
      repeatDelay: 1,
      ease: 'easeOut',
    },
  },
  hover: {
    scale: 1.25,
    rotate: 315,
  },
})

const ThemeSwitcher: React.FC<Props> = ({
  nextTheme,
  onChangeThemeCallback,
}) => {
  return (
    <motion.div
      css={css`
        position: fixed;
        top: 1em;
        right: 1em;
        margin: 0.5em;
        cursor: pointer;
      `}
      variants={containerVariants}
      initial="unhovered"
      whileHover="hover"
      whileTap="hover"
      onClick={onChangeThemeCallback}
    >
      <motion.div
        css={css`
          background-color: rgb(${nextTheme.border});
          height: 1em;
          width: 1em;
          margin: 0.75em;
        `}
        variants={colorVariants(0)}
      />
      <motion.div
        css={css`
          background-color: rgb(${nextTheme.main});
          height: 1em;
          width: 1em;
          margin: 0.75em;
        `}
        variants={colorVariants(0.1)}
      />
      <motion.div
        css={css`
          background-color: rgb(${nextTheme.textColor});
          height: 1em;
          width: 1em;
          margin: 0.75em;
        `}
        variants={colorVariants(0.2)}
      />
      <motion.div
        css={css`
          background-color: rgb(${nextTheme.textSelectionColor});
          height: 1em;
          width: 1em;
          margin: 0.75em;
        `}
        variants={colorVariants(0.3)}
      />
    </motion.div>
  )
}

export default ThemeSwitcher
